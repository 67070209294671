<template>
  <div>
    <el-table
      class="report-rss-table"
      size="mini"
      v-loading="loading"
      border
      empty-text="Нет данных"
      :data="data.procedure_users"
      :row-key="(row) => row.user_id"
      header-row-class-name="report-rss-header"
      :cell-class-name="getCellClass"
      @sort-change="sortChanged($event, loadProcedureReport)"
    >
      <el-table-column width="120" header-align="top" label="Личный номер КМ" prop="chief.persnum" sortable="custom"></el-table-column>
      <el-table-column width="150" label="Компенсационный менеджер" prop="chief.full_name" sortable="custom"></el-table-column>
      <el-table-column width="120" label="Личный номер сотрудника" prop="persnum" sortable="custom" v-slot="scope">{{scope.row.historyUser ? scope.row.historyUser.persnum : ''}}</el-table-column>
      <el-table-column width="120" label="ФИО сотрудника" prop="full_name" sortable="custom">
        <template v-slot="scope">
          {{scope.row.historyUser ? scope.row.historyUser.last_name : ''}} {{scope.row.historyUser ? scope.row.historyUser.first_name : ''}} {{scope.row.historyUser ? scope.row.historyUser.middle_name : ''}}
        </template>
      </el-table-column>
      <el-table-column width="120" label="Должность" prop="position" sortable="custom" v-slot="scope">{{scope.row.historyUser ? scope.row.historyUser.position : ''}}</el-table-column>
      <el-table-column width="120" label="Дирекция" prop="direction" sortable="custom" v-slot="scope">{{scope.row.historyUser ? scope.row.historyUser.direction : ''}}</el-table-column>
      <el-table-column width="100" label="Цех" prop="workshop" sortable="custom" v-slot="scope">{{scope.row.historyUser ? scope.row.historyUser.workshop : ''}}</el-table-column>
      <el-table-column width="120" label="Локация" prop="location" sortable="custom" v-slot="scope">{{scope.row.historyUser ? scope.row.historyUser.location : ''}}</el-table-column>
      <el-table-column width="100" prop="historyUser.hire_date" label="Дата приема" sortable="custom" v-slot="scope">
        <div v-if="!scope.row.historyUser">Нет данных на дату процедуры</div>
        <div v-else>{{new Date(scope.row.historyUser.hire_date).toLocaleDateString($companyConfiguration.helpers.getDateLocale())}}</div>
      </el-table-column>
      <el-table-column width="100" label="Бенд" prop="band" sortable="custom" v-slot="scope">{{scope.row.historyUser ? scope.row.historyUser.band : ''}}</el-table-column>
      <el-table-column width="120" label="Текущий оклад, руб." prop="current_salary" sortable="custom" v-slot="scope">{{+scope.row.current_salary|numberFormat}}</el-table-column>
      <el-table-column width="120" label="Middle диапазона оплаты труда, руб." prop="mid_range_pay" sortable="custom"></el-table-column>
      <el-table-column width="120" label="Min диапазона оплаты труда, руб." prop="min_range_pay" sortable="custom" v-slot="scope">{{+scope.row.min_range_pay|numberFormat}}</el-table-column>
      <el-table-column width="120" label="Max диапазона оплаты труда, руб." prop="max_range_pay" sortable="custom" v-slot="scope">{{+scope.row.max_range_pay|numberFormat}}</el-table-column>
<!--      <el-table-column width="120" label="Текущая позиция" prop="current_position_in_range" sortable="custom"></el-table-column>-->
      <el-table-column width="120" label="Уровень оклада в диапазоне (текущая позиция), %" prop="level_of_current_salary_in_range_current_position" sortable="custom"></el-table-column>
      <el-table-column width="100" label="Итоговая оценка" prop="evaluate_final_efficiency_id"  sortable="custom" v-slot="scope">
        {{getEvaluationNameById(scope.row.evaluate_final_efficiency_id)}}
      </el-table-column>
      <el-table-column width="150" label="Минимальный % повышения" prop="min_percent_of_raise" sortable="custom"></el-table-column>
      <el-table-column width="150" label="Средний рекомендованный % повышения" prop="mid_percent_of_raise" sortable="custom"></el-table-column>
      <el-table-column width="150" label="Максимальный % повышения" prop="max_percent_of_raise" sortable="custom"></el-table-column>
      <el-table-column width="300" label="Уведомление!" prop="notifications" sortable="custom" v-slot="scope">
        <div :class="{'has-notification': scope.row.notifications && JSON.parse(scope.row.notifications).length}">
          <div v-for="notification in JSON.parse(scope.row.notifications)">
            {{notification}}
          </div>
        </div>
      </el-table-column>
      <el-table-column width="150" label="Предлагаемый % повышения" prop="suggested_percent_of_raise" sortable="custom"></el-table-column>
      <el-table-column width="150" label="Предлагаемая сумма повышения, руб" prop="suggested_sum_of_raise" sortable="custom"></el-table-column>
      <el-table-column width="150" label="Право на получение единовременной премии" prop="can_get_bonus" sortable="custom" v-slot="scope">
        {{scope.row.can_get_bonus ? 'Да' : ''}}
      </el-table-column>
      <el-table-column width="150" label="Предлагаемый % единовременной премии" prop="suggested_percent_of_ones_bonus" sortable="custom"></el-table-column>
      <el-table-column width="170" label="Предлагаемая сумма единовременной премии ( в годовом выражении), руб." prop="suggested_sum_of_ones_bonus" sortable="custom"></el-table-column>
      <el-table-column width="170" label="Общий % повышения с учетом единовременной премии" prop="total_percent_of_raise" sortable="custom"></el-table-column>
      <el-table-column width="150" label="Оклад после повышения, руб" prop="current_salary_after_raise" sortable="custom" v-slot="scope">{{+scope.row.current_salary_after_raise|numberFormat}}</el-table-column>
<!--      <el-table-column width="150" label="Позиция оклада в диапазоне" prop="position_of_current_salary_in_range" sortable="custom"></el-table-column>-->
      <el-table-column width="150" label="уровень оклада в диапазоне (новая позиция), %" prop="level_of_current_salary_in_range_new_position" sortable="custom"></el-table-column>
      <el-table-column width="300" label="Комментарий руководителя" prop="comments" sortable="custom"></el-table-column>

    </el-table>

    <paginator
      :paginator.sync="paginator"
      @page-changed="loadProcedureReport"
    ></paginator>

  </div>
</template>

<script>


import AddUsersDialog from "@/components/user/AddUsersDialog.vue";
import ClickToEdit from "@/components/ClickToEdit.vue";
import requestSender from "@/api/base/requestSender";
import {paginator} from "@/mixins/paginator";
import {sorter} from "@/mixins/sorter";
import Paginator from "@/components/Paginator.vue";

export default {
  name: "RaiseRssTemplate",
  components: {Paginator, ClickToEdit, AddUsersDialog},
  mixins: [paginator, sorter],
  props: {
    procedureId: {type: Number, required: true},
    evaluations: {type: Object, required: true},
  },

  watch: {
    procedureId: function (newVal) {
      if (!this.procedureId) {
        return;
      }
      this.loadProcedureReport();
    },
  },
  data() {
    return {
      loading: false,
      data: {},
    }
  },

  mounted() {
    this.loadProcedureReport()
  },

  methods: {
    getEvaluationNameById(evaluationId){
      return this.evaluations[4]?.find(e=>e.id===evaluationId)?.name;
    },

    loadProcedureReport() {
      this.loading = true;

      requestSender('get', 'compensation-procedure/get-report', {
        procedure_id: this.procedureId,

        [this.sorter.param]: this.sorter.sort,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
        expand: 'historyUser, chief',
      })
        .then(({data, paginator, sorter}) => {
          this.data = data;
          this.paginatorFill(paginator);
          this.sorterFill(sorter);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getCellClass({row, column, rowIndex, columnIndex}){
      let classes = [];
      if( ['chief.full_name', 'full_name', 'position'].includes(column.property) ){
        classes.push('left-col');
      } else{
        classes.push('centered-col');
      }

      if( ['full_name', 'evaluate_final_efficiency_id', 'suggested_percent_of_raise', 'suggested_percent_of_ones_bonus', 'current_salary_after_raise'].includes(column.property) ){
        classes.push('bold-col');
      }

      if( ['evaluate_final_efficiency_id', 'suggested_percent_of_raise', 'suggested_percent_of_ones_bonus', 'current_salary_after_raise'].includes(column.property) ){
        classes.push('color-1-col');
      }
      if( ['mid_percent_of_raise'].includes(column.property) ){
        classes.push('color-2-col');
      }
      if( ['notifications'].includes(column.property) ){
        classes.push('color-3-col');
      }

      return classes.join(' ');
    },
  },


}
</script>

<style lang="scss">
@import "node_modules/element-ui/packages/theme-chalk/src/common/var.scss";
@import "@/assets/css/project-variables";

.report-rss-table{
  .centered-col{
    text-align: center !important;
  }
  .bold-col{
    font-weight: bold !important;
  }
  .color-1-col{
    background-color: #c2ddee;
  }
  .color-2-col{
    background-color: #ECECEC;
  }
  .color-3-col{
    background-color: #FEF2CB;
  }
}

.report-rss-header{
  th{
    color: #141414 !important;
    background-color: $--color-warning !important;
    vertical-align: top !important;
    font-weight: bold !important;
    text-align: center !important;
  }
}
</style>